import request from '@/utils/request'


// 查询新-规格口味组成列表
export function listFlavorOption(query) {
  return request({
    url: '/cigarette/sku-flavor-option/list',
    method: 'get',
    params: query
  })
}

// 查询新-规格口味组成分页
export function pageFlavorOption(query) {
  return request({
    url: '/cigarette/sku-flavor-option/page',
    method: 'get',
    params: query
  })
}

// 查询新-规格口味组成详细
export function getFlavorOption(data) {
  return request({
    url: '/cigarette/sku-flavor-option/detail',
    method: 'get',
    params: data
  })
}

// 新增新-规格口味组成
export function addFlavorOption(data) {
  return request({
    url: '/cigarette/sku-flavor-option/add',
    method: 'post',
    data: data
  })
}

// 修改新-规格口味组成
export function updateFlavorOption(data) {
  return request({
    url: '/cigarette/sku-flavor-option/edit',
    method: 'post',
    data: data
  })
}

// 删除新-规格口味组成
export function delFlavorOption(data) {
  return request({
    url: '/cigarette/sku-flavor-option/delete',
    method: 'post',
    data: data
  })
}
